import "./css/App.css";
import AllUsersChart from "./AllUsersChart";
import UsersByNational from "./UsersByNationalChart";
import FemaleInTop5Countries from "./Top5CountriesFemaleChart";

function App() {
  return (
    <div className="App">
      <AllUsersChart />
      <UsersByNational />
      <FemaleInTop5Countries />
    </div>
  );
}

export default App;
