import { useState, useEffect } from "react";
import { Users } from "../lib/statistics";
import { Chart } from "react-google-charts";
import "./css/Chart.css";
import { barChartHeight } from "../const/const";
import { getWidth } from "../util/chartUtils";

function FemaleInTop5Countries() {
  const users = new Users();
  const usersUSOrUK = new Users(["US", "GB"]);

  const [allUsersData, setAllUsersData] = useState([]);
  const [USUKUsersData, setUSUKUsersData] = useState([]);

  useEffect(() => {
    users.getUsers().then(() => {
      const data = users.genderByTopCountires(5);
      setAllUsersData(data);
    });
    usersUSOrUK.getUsers().then(() => {
      const data = usersUSOrUK.genderByTopCountires(5);
      setUSUKUsersData(data);
    });
  }, []);

  const allUsersOptions = {
    chart: {
      title: "All Nationals",
    },
  };

  const USUKUsersOptions = {
    chart: {
      title: "US or UK Nationals",
    },
  };

  return (
    <div>
      <div className="title">
        Female Population in Top 5 populated countries
      </div>
      <div className="chart">
        {allUsersData.length ? (
          <Chart
            chartType="Bar"
            data={allUsersData}
            options={allUsersOptions}
            width={getWidth(allUsersData.length)}
            height={barChartHeight}
          />
        ) : null}
        {USUKUsersData.length ? (
          <Chart
            chartType="Bar"
            data={USUKUsersData}
            options={USUKUsersOptions}
            width={getWidth(USUKUsersData.length)}
            height={barChartHeight}
          />
        ) : null}
      </div>
    </div>
  );
}

export default FemaleInTop5Countries;
