import { useState, useEffect } from "react";
import { Users } from "../lib/statistics";
import { Chart } from "react-google-charts";
import "./css/Chart.css";
import { pieChartSize } from "../const/const";

function UsersByNational() {
  const usersUSOrUK = new Users(["US", "GB"]);

  const [totalCount, setTotalCount] = useState(0);
  const [femaleData, setFemaleData] = useState([]);
  const [ageData, setAgeData] = useState([]);
  const [err, setErr] = useState("");

  useEffect(() => {
    usersUSOrUK
      .getUsers()
      .then((count) => setTotalCount(count))
      .then(() => {
        const femaleCount = usersUSOrUK.countFemaleUsers();
        setFemaleData(femaleCount);

        const ageData = usersUSOrUK.countAgeGroup();
        setAgeData(ageData);
      });
  }, []);

  const femaleOptions = {
    title: "Female Population",
  };
  const ageOptions = {
    title: "Age Group Distribution",
  };

  const handleClick = async () => {
    const data = await usersUSOrUK.downloadCSV();
    if (data.length !== 0) {
      window.open("").document.write(data);
    } else {
      setErr("Try again!");
    }
  };

  return (
    <div>
      <div className="title">
        Population count of all users with US or UK nationalities: {totalCount}
      </div>
      <button onClick={handleClick}>Download CSV</button>
      {err ? <div className="error">{err}</div> : null}
      <div className="chart">
        {femaleData.length ? (
          <Chart
            chartType="PieChart"
            data={femaleData}
            options={femaleOptions}
            width={pieChartSize}
            height={pieChartSize}
          />
        ) : null}
        {ageData.length ? (
          <Chart
            chartType="PieChart"
            data={ageData}
            options={ageOptions}
            width={pieChartSize}
            height={pieChartSize}
          />
        ) : null}
      </div>
    </div>
  );
}

export default UsersByNational;
