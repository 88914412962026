import axios from "axios";
// server port
axios.defaults.baseURL =
  process.env.NODE_ENV === "production"
    ? "https://bloom-tech-charts-web-server.onrender.com"
    : "http://localhost:8081";

export class Users {
  constructor(nationals) {
    this.nationals = nationals ? [...nationals] : null;
    this.users = [];
    this.count = 0;
  }

  getQuery() {
    let query = "";
    if (this.nationals) {
      if (query.length === 0) query += "?";
      else query += "&";
      query += `nat=${this.nationals.join(",").toUpperCase()}`;
    }
    return query;
  }

  // GET call to retrive users in JSON format
  async getUsers() {
    const endpoint = "/users";
    const query = this.getQuery();
    const { data: users } = await axios.get(endpoint + query);
    if (users.length) {
      this.users = [...users];
      this.count = this.users.length;
    }
    return this.count;
  }

  countFemaleUsers() {
    let femaleCount = 0;
    this.users.forEach((user) => {
      if (user.gender === "female") femaleCount++;
    });

    const otherCount = this.count - femaleCount;

    return [
      ["Gender", "Count"],
      ["Female", femaleCount],
      ["Other", otherCount],
    ];
  }

  countAgeGroup() {
    let under16 = 0;
    let from16to25 = 0;
    let from26to45 = 0;
    let from46to65 = 0;
    let from66to85 = 0;
    let over85 = 0;
    this.users.forEach((user) => {
      const age = user.age;
      if (age < 16) under16++;
      else if (age < 26) from16to25++;
      else if (age < 46) from26to45++;
      else if (age < 66) from46to65++;
      else if (age < 86) from66to85++;
      else over85++;
    });

    const data = [["Age Group", "Count"]];
    data.push(["Under 16", under16]);
    data.push(["16-25", from16to25]);
    data.push(["26-45", from26to45]);
    data.push(["46-65", from46to65]);
    data.push(["66-85", from66to85]);
    data.push(["Over 85", over85]);

    return data;
  }

  // method returns numbers of top countries specified by the num argument
  genderByTopCountires(num) {
    const countryBook = {};
    this.users.forEach((user) => {
      const country = user.country;
      if (!countryBook[country])
        countryBook[country] = [country, 1, user.gender === "female" ? 1 : 0];
      else {
        countryBook[country][1]++;
        if (user.gender === "female") {
          countryBook[country][2]++;
        }
      }
    });

    const countryArr = Object.values(countryBook);
    if (countryArr.length <= num) {
      countryArr.unshift(["Country", "Total Population", "Female"]);
      return countryArr;
    }
    countryArr.sort((a, b) => {
      return countryBook[[a][1]] - countryBook[[b][1]];
    });

    const data = countryArr.slice(0, num);
    data.unshift(["Country", "Total Population", "Female"]);
    return data;
  }

  // GET call to retrive users in CSV format
  async downloadCSV() {
    const endpoint = "/users";
    let query = this.getQuery();
    if (query.length === 0) query += "?format=csv";
    else query += "&format=csv";
    const { data: users } = await axios.get(endpoint + query);
    return users;
  }
}
