import { useState, useEffect } from "react";
import { Users } from "../lib/statistics";
import { Chart } from "react-google-charts";
import "./css/Chart.css";
import { pieChartSize } from "../const/const";

function AllUsersChart() {
  const users = new Users();
  const [totalCount, setTotalCount] = useState(0);
  const [femaleData, setFemaleData] = useState([]);
  const [ageData, setAgeData] = useState([]);
  const [err, setErr] = useState("");

  useEffect(() => {
    users
      .getUsers()
      .then((count) => setTotalCount(count))
      .then(() => {
        const femaleCount = users.countFemaleUsers();
        setFemaleData(femaleCount);

        const ageData = users.countAgeGroup();
        setAgeData(ageData);
      });
  }, []);

  const femaleOptions = {
    title: "Female Population %",
  };
  const ageOptions = {
    title: "Age Group Distribution",
  };

  const handleClick = async () => {
    const data = await users.downloadCSV();
    if (data.length !== 0) {
      window.open("").document.write(data);
    } else {
      setErr("Try again!");
    }
  };

  return (
    <div>
      <div className="title">Population count of all users: {totalCount}</div>
      <button onClick={handleClick}>Download CSV</button>
      {err ? <div className="error">{err}</div> : null}
      <div className="chart">
        {femaleData.length ? (
          <Chart
            chartType="PieChart"
            data={femaleData}
            options={femaleOptions}
            width={pieChartSize}
            height={pieChartSize}
          />
        ) : null}
        {ageData.length ? (
          <Chart
            chartType="PieChart"
            data={ageData}
            options={ageOptions}
            width={pieChartSize}
            height={pieChartSize}
          />
        ) : null}
      </div>
    </div>
  );
}

export default AllUsersChart;
